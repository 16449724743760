// src/Components/StyledElearningCard.js
import { Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)({
	height: 280,
	position: 'relative',
	border: '1px solid #777777',
	borderRadius: '10px',
	boxShadow: 'none',
	'&:hover': {
		border: '2px solid #00AA45',
		cursor: 'pointer',
	},
	'&:hover .media': {
		transform: 'scale(1.05)', // Add scaling effect
	},
	'&:hover .mediaWrapper': {
		borderBottom: '2px solid #00AA45',
	},
	'&:hover .logoContainer': {
		backgroundColor: '#00AA45',
		border: '1px solid #00AA45',
	},
	'&:hover .grayIcon': {
		display: 'none',
	},
	'&:hover .whiteIcon': {
		display: 'block',
	},
	'&:hover .icon': {
		color: 'white',
	},
});

export const StyledCardContent = styled(CardContent)({
	height: '100%',
	backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
	borderRadius: '10px',
});

export default StyledCard;
