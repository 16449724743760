import React, { useState, useContext, useEffect } from 'react';
import axiosConfig from '../../components/utils/axiosConfig';
import jwt from 'jwt-decode';
import { Typography, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
	BottomLine,
	GraySection,
	InputGroup,
	MainContainer,
	StyledButton,
	StyledInput,
} from './StyledComponents';
import Navbar from './../../components/Navbar/Navbar';
import { useMediaQuery } from '@mui/material';
import { GlobalContext } from './../../context/GlobalState';
import '../../fonts/roboto.css';
import { toast } from 'react-toastify';
import enablerLogo from '../../assets/images/homepage/logoNew.png';
import { useLoginMutation } from '../../store/middleware/authApi';
import { loginReducer } from '../../store/reducers/authReducer';
import { useDispatch } from 'react-redux';

function Login({ setRecoverEmail }) {
	const { userLogin } = useContext(GlobalContext);

	const [login, { isLoading, error }] = useLoginMutation();

	const isMobile = useMediaQuery('(max-width:600px)');
	const dispatch = useDispatch();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		localStorage.removeItem('token');
	}, []);

	const navigate = useNavigate();

	const handleSubmitEmail = async () => {
		// if (!email) {
		// 	toast.error('Please enter your email id');
		// 	return;
		// }
		// try {
		// 	const result = await axiosConfig.post('/users/forgot-password', {
		// 		email,
		// 	});
		// 	setRecoverEmail(email);
		// 	toast.success('Please check your email for further instructions');
		// 	navigate('/forgot-password');
		// } catch (error) {
		// 	if (error.code == 'ERR_BAD_REQUEST') {
		// 		toast.error(error.response.data.error);
		// 	}
		// 	if (error.code == 'ERR_BAD_RESPONSE') {
		// 		toast.error(
		// 			'There is a problem with the server at the moment. Please try again later'
		// 		);
		// 	}
		// 	console.log(error);
		// }
		navigate('/forgot-password');
	};

	const handleSubmit = async () => {
		if (!email) {
			toast.error('Please enter your email id');
			return;
		}
		if (!password) {
			toast.error('Please enter your password');
			return;
		}
		try {
			login({ email, password });
			const {
				data: { token },
			} = await axiosConfig.post('/users/login', {
				email,
				password,
			});

			const { data: usertype } = await jwt(token);
			dispatch(loginReducer(usertype));

			let color =
				usertype.usertype == 'Sustent Group' ||
				usertype.usertype == 'Super Admin'
					? '#00AA45'
					: usertype.usertype == 'Client' || usertype.usertype == 'Admin'
					? '#294D8F'
					: '#1E989A';
			localStorage.setItem('token', JSON.stringify(token));
			userLogin(usertype);

			if (usertype.usertype == 'Admin' || usertype.usertype == 'Super Admin') {
				toast.info('Please login from the Admin Login page');
				setTimeout(() => {
					window.location.href = '/login';
				}, 2000);
			} else {
				const assignedTools = await axiosConfig.get(
					`/common/get-user-tools/${usertype.userId}`
				);
				const assignedToolIdArray = assignedTools?.data?.result[0]?.map(
					(tool) => tool.tool_id
				);
				if (assignedToolIdArray.length == 0) {
					window.location.href = '/no-access';
					return;
				}
				if (
					[12, 13, 14, 15].some((toolId) =>
						assignedToolIdArray.includes(toolId)
					)
				) {
					window.location.href = '/integrated-services';
					return;
				}
				if (
					[16, 17, 18, 19, 20, 21, 22, 23, 24, 25].some((toolId) =>
						assignedToolIdArray.includes(toolId)
					)
				) {
					window.location.href = '/specialist-services';
					return;
				}
				if (
					[26, 27, 28, 29, 30, 31, 32, 33, 34].some((toolId) =>
						assignedToolIdArray.includes(toolId)
					)
				) {
					window.location.href = '/systemized-services';
					return;
				}
				if (
					[1, 2, 3, 4, 5, 6, 7, 8, 9].some((toolId) =>
						assignedToolIdArray.includes(toolId)
					)
				) {
					window.location.href = '/tools';
					return;
				}
				if (
					[56, 57, 58, 59].some((toolId) =>
						assignedToolIdArray.includes(toolId)
					)
				) {
					window.location.href = '/eLearning';
					return;
				}
				if (
					[40, 41, 42, 43].some((toolId) =>
						assignedToolIdArray.includes(toolId)
					)
				) {
					window.location.href = '/templates';
					return;
				}
			}

			return;
		} catch (error) {
			if (error.code == 'ERR_BAD_REQUEST') {
				toast.error('Invalid credentials');
			}
			console.log(error);
		}
	};

	const sendMail = () => {
		navigate('/#request-access');
	};

	return (
		<Box>
			{isMobile && <Navbar />}
			<MainContainer>
				<GraySection>
					<Box
						sx={{ marginTop: { lg: '60px', xs: isMobile ? '80px' : '20px' } }}
					>
						{!isMobile && (
							<img
								src={enablerLogo}
								width="120px"
								onClick={() => navigate('/')}
								style={{ cursor: 'pointer' }}
							/>
						)}
					</Box>
					<Typography
						fontFamily="Roboto,sans-serif"
						variant="h3"
						color="#294D8F"
						gutterBottom
						sx={{
							fontWeight: { xs: 'bold' },
						}}
					>
						Welcome to <i style={{ fontWeight: '900' }}>enabler</i>!
					</Typography>
					{/* <Typography
						variant="subtitle1"
						color="#888888"
						sx={{ fontSize: '20px' }}
					>
						Tagline/Explanatory sentence come here
					</Typography> */}
				</GraySection>
				<Box>
					<InputGroup>
						<StyledInput
							label="Enter Your Registered Email"
							size="small"
							sx={{ width: { xs: '90%', lg: '30%' } }}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							name="Username"
							InputProps={{
								style: {
									borderRadius: '50px',
								},
							}}
						/>
						<StyledInput
							label="Enter Password"
							style={{ marginTop: '15px' }}
							size="small"
							name="Password"
							sx={{ width: { lg: '30%', xs: '90%' }, color: 'gray' }}
							onChange={(e) => setPassword(e.target.value)}
							value={password}
							type="password"
							InputProps={{
								style: {
									borderRadius: '50px',
								},
							}}
							// InputLabelProps={{
							// 	style: {
							// 		fontSize: '14px',
							// 		marginTop: '3px',
							// 		marginLeft: '5px',
							// 	},
							// }}
						/>
						<Typography
							sx={{
								fontSize: '12px',
								marginTop: '8px',
								textDecoration: 'underline',
								width: { lg: '30%', xs: '90%' },
								textAlign: 'left',
								paddingLeft: '10px',
								cursor: 'pointer',
							}}
							onClick={handleSubmitEmail}
						>
							Forgot Password ?
						</Typography>
						<StyledButton onClick={handleSubmit}>Login</StyledButton>
						{/* <Typography variant="p" gutterBottom>
							New to Enabler?
							<span
								style={{
									color: '#909090',
									textDecoration: 'underline',
									cursor: 'pointer',
								}}
								onClick={sendMail}
							>
								&nbsp;Request Access
							</span>
						</Typography> */}
						<BottomLine
							sx={{
								fontSize: { xs: '12px', lg: '12px' },
								padding: { xs: '5rem 2.6rem 2.5rem', lg: '2rem 2rem 2rem' },
							}}
						>
							By clicking 'Login', you acknowledge that you have read and
							understood, and you agree to the <br />
							<Link
								style={{ textDecoration: 'none', color: 'gray' }}
								to="/terms-and-conditions"
							>
								<span style={{ textDecoration: 'underline', color: 'gray' }}>
									{' '}
									Terms and Conditions
								</span>{' '}
								&nbsp;&nbsp;& &nbsp;{' '}
							</Link>
							<Link style={{ textDecoration: 'none' }} to="/privacy-policy">
								<span style={{ textDecoration: 'underline', color: 'gray' }}>
									Privacy Policy
								</span>
							</Link>
						</BottomLine>

						<Typography fontSize="12px">
							Powered By &nbsp;
							{/* <a style={{ color: 'gray' }} href="https://sustent.in"> */}
							SUSTENT
							{/* </a> */}
						</Typography>
					</InputGroup>
				</Box>
			</MainContainer>
		</Box>
	);
}

export default Login;
