import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const satApi = createApi({
	reducerPath: 'satApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_KEY}`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().auth.token;
			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}
			return headers;
		},
		credentials: 'include',
	}),
	endpoints: (builder) => ({
		getWeightageData: builder.query({
			query: (userId) => `/sat/get-weightage-data/${userId}`,
		}),
		getResultData: builder.query({
			query: (userId) => `/sat/get-result-data/${userId}`,
		}),
		getQuestionnaires: builder.query({
			query: () => `sat/getQuestionaires`,
		}),
		saveWeightageData: builder.mutation({
			query: (data) => ({
				url: '/sat/theme-weightage-data',
				method: 'POST',
				body: data,
			}),
		}),
		saveResultData: builder.mutation({
			query: ({ userId, points, recommendations }) => ({
				url: '/sat/save-result-data',
				method: 'POST',
				body: {
					userId,
					energy: points.Energy,
					noise: points.Noise,
					waste: points.Waste,
					water: points.Water,
					air: points.Air,
					recommendations,
				},
			}),
		}),
	}),
});

export const {
	useGetWeightageDataQuery,
	useGetResultDataQuery,
	useGetQuestionnairesQuery,
	useSaveWeightageDataMutation,
	useSaveResultDataMutation,
} = satApi;
