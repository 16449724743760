// src/Components/StyledEllipsisTypography.js
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const EllipsisTypography = styled(Typography)({
	display: '-webkit-box',
	WebkitLineClamp: 3,
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textTransform: 'capitalize',
	lineHeight: 1.3,
	marginBottom: '12px',
});

export default EllipsisTypography;
