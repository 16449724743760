import axios from 'axios';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
	baseURL: `${process.env.REACT_APP_API_KEY}/admin`,
	withCredentials: true,
});

// Add a request interceptor to handle requests globally
axiosInstance.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		config.headers['Content-Type'] = 'application/json';
		return config;
	},
	(error) => {
		// Handle request errors globally
		console.error('Request Interceptor Error:', error);
		return Promise.reject(error);
	}
);

// Add a response interceptor to handle responses globally
axiosInstance.interceptors.response.use(
	(response) => {
		// You can modify the response here, such as extracting data, etc.
		console.log('Response Interceptor:', response);
		return response;
	},
	(error) => {
		// Handle response errors globally
		console.error('Response Interceptor Error:', error);
		if (error?.response?.data?.msg) {
			toast.error(error.response.data.msg);
		}
		throw error; // Rethrow the error to propagate it further
	}
);

export default axiosInstance;
