import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import logoNew from '../../assets/images/homepage/logoNew.png';
import AnimatedButton from '../Home/AnimatedButton';

function Navbar() {
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width:600px)');

	return (
		<Box
			position="fixed"
			display="flex"
			justifyContent="space-between"
			backgroundColor="transparent"
			mt={2}
			zIndex={700}
			left={0}
			right={0}
			maxWidth="100%"
			mx={isMobile ? 2 : 3}
		>
			<Box>
				<a style={{ textDecoration: 'none' }} href="#top">
					<img
						src={logoNew}
						height={isMobile ? '35px' : '45px'}
						width={isMobile ? '70px' : '100px'}
						style={{ cursor: 'pointer' }}
						onClick={() => navigate('/')}
						alt="logo"
					/>
				</a>
			</Box>
			<Link to="/login" style={{ textDecoration: 'none' }}>
				<AnimatedButton
					bgColor="#ffffff"
					textColor="#00AA45"
					arrowColor="white"
					arrowBgColor="#00AA45"
					text="Log In"
					translateXIcon={isMobile ? 65 : 90}
					translateXText={isMobile ? -25 : -35}
					hoverBgColor="#ffffff"
					hoverTextColor="#00AA45"
					hoverArrowColor="white"
					hoverArrowBgColor="#00AA45"
				/>
			</Link>
		</Box>
	);
}

export default Navbar;
