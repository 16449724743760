import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	token: null,
	isLoggedIn: false,
	userId: null,
	userType: null,
	email: null,
	isStenumAdmin: false,
	isSuperAdmin: false,
	isReapDsAdmin: false,
	themeColor: '#294D8F',
	username: '',
	organization: '',
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		loginReducer(state, action) {
			let color =
				action.payload.usertype == 'Sustent Group'
					? '#575757'
					: action.payload.usertype == 'Client'
					? '#00AA45'
					: '#294D8F';
			state.token = action.payload.token;
			state.userId = action.payload.userId;
			state.userType = action.payload.usertype;
			state.isStenumAdmin = action.payload.isStenumAdmin;
			state.isSuperAdmin = action.payload.isSuperAdmin;
			state.isReapDsAdmin = action.payload.isReapDsAdmin;
			state.email = action.payload.email;
			state.isLoggedIn = true;
			state.themeColor = color;
			state.username = action.payload.username;
			state.organization = action.payload.organization;
		},

		logoutUser(state) {
			state.token = null;
			state.userType = null;
			state.isStenumAdmin = false;
			state.isSuperAdmin = false;
			state.isReapDsAdmin = false;
			state.isLoggedIn = false;
			state.themeColor = '#294D8F';
			state.email = null;
			state.username = '';
		},
	},
});

export const { loginReducer, logoutUser } = authSlice.actions;
export default authSlice.reducer;
