import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBaseQuery from '../../components/utils/customBaseQuery';

export const queryManagerApi = createApi({
	reducerPath: 'queryManagerApi',
	baseQuery: customFetchBaseQuery('query-manager'),
	tagTypes: ['queries'],
	endpoints: (builder) => ({
		getAccessRequests: builder.query({
			query: () => '/get-access-requests', // This is the endpoint for the GET request
			providesTags: ['queries'],
		}),
		blockIp: builder.mutation({
			query: (ipData) => ({
				url: '/block-ip', // This is the endpoint for the POST request
				method: 'POST',
				body: ipData,
			}),
			invalidatesTags: ['queries'],
		}),
	}),
});

export const { useGetAccessRequestsQuery, useBlockIpMutation } =
	queryManagerApi;
