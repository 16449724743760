import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const newsApi = createApi({
	reducerPath: 'newsApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_KEY}`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().auth.token;
			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}
			return headers;
		},
		credentials: 'include',
	}),
	tagTypes: ['News'],
	endpoints: (builder) => ({
		getNews: builder.query({
			query: () => 'news',
			providesTags: ['News'],
		}),
		addNews: builder.mutation({
			query: (news) => ({
				url: 'news',
				method: 'POST',
				body: news,
			}),
			invalidatesTags: ['News'],
		}),
		deleteNews: builder.mutation({
			query: (id) => ({
				url: `news/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['News'],
		}),
	}),
});

export const { useGetNewsQuery, useAddNewsMutation, useDeleteNewsMutation } =
	newsApi;
