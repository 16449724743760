import { Box, Stack, TextField, Typography } from '@mui/material';
import React, { useState, useContext, useEffect } from 'react';
import Navbar from '../../../components/Navbar/Navbar';
import { useLoginMutation } from '../../../store/middleware/authApi';
import { loginReducer } from '../../../store/reducers/authReducer';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import '../../../fonts/roboto.css';
import enablerLogo from '../../../assets/images/homepage/logoNew.png';
import { useMediaQuery } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import { GlobalContext } from '../../../context/GlobalState';
import axiosConfig from '../../../components/utils/axiosConfig';
import {
	BottomLine,
	GraySection,
	InputGroup,
	MainContainer,
	StyledButton,
	StyledInput,
} from '../../Login/StyledComponents';

const AdminLogin = () => {
	const { userLogin } = useContext(GlobalContext);
	const [login, { isLoading, error }] = useLoginMutation();

	const isMobile = useMediaQuery('(max-width:600px)');
	const dispatch = useDispatch();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		if (localStorage.getItem('token')) {
			localStorage.removeItem('token');
		}
	}, []);

	const navigate = useNavigate();

	const handleSubmitEmail = async () => {
		if (!email) {
			toast.error('Please enter your email id');
			return;
		}
		try {
			const result = await axiosConfig.post('/users/forgot-password', {
				email,
			});

			toast.success('Please check your email for further instructions');
			navigate('/forgot-password');
		} catch (error) {
			if (error.code == 'ERR_BAD_REQUEST') {
				toast.error(error.response.data.error);
			}
			if (error.code == 'ERR_BAD_RESPONSE') {
				toast.error(
					'There is a problem with the server at the moment. Please try again later'
				);
			}
			console.log(error);
		}
	};

	const handleSubmit = async () => {
		if (!email) {
			toast.error('Please enter your email id');
			return;
		}
		if (!password) {
			toast.error('Please enter your password');
			return;
		}
		try {
			login({ email, password });
			const {
				data: { token, resourceToken },
			} = await axiosConfig.post('/users/login', {
				email,
				password,
			});

			const { data: usertype } = await jwt(token);
			dispatch(loginReducer(usertype));

			let color =
				usertype.usertype == 'Sustent Group' ||
				usertype.usertype == 'Super Admin'
					? '#00AA45'
					: usertype.usertype == 'Client' || usertype.usertype == 'Admin'
					? '#294D8F'
					: '#1E989A';
			localStorage.setItem('token', JSON.stringify(token));
			localStorage.setItem('resourceToken', JSON.stringify(resourceToken));
			userLogin(usertype);

			if (usertype.usertype == 'Admin' || usertype.usertype == 'Super Admin') {
				window.location.href = '/admin';
			} else {
				window.location.href = '/login';
				toast.info(`You don't have the Admin Access`);
			}

			return;
		} catch (error) {
			if (error.code == 'ERR_BAD_REQUEST') {
				toast.error('Invalid credentials');
			}
			console.log(error);
		}
	};
	return (
		<Box>
			{isMobile && <Navbar />}
			<MainContainer>
				<GraySection>
					<Box
						sx={{ marginTop: { lg: '60px', xs: isMobile ? '80px' : '20px' } }}
					>
						{!isMobile && <img src={enablerLogo} width="120px" />}
					</Box>
					<Typography
						fontFamily="Roboto,sans-serif"
						variant="h3"
						color="#294D8F"
						gutterBottom
						sx={{
							fontWeight: { xs: 'bold' },
						}}
					>
						Welcome to <i style={{ fontWeight: '900' }}>enabler</i> admin!
					</Typography>
				</GraySection>
				<Box>
					<InputGroup>
						<StyledInput
							label="Enter Your Registered Email"
							size="small"
							sx={{ width: { xs: '90%', lg: '30%' } }}
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							name="Username"
							InputProps={{
								style: {
									borderRadius: '50px',
								},
							}}
						/>
						<StyledInput
							label="Enter Password"
							style={{ marginTop: '15px' }}
							size="small"
							name="Password"
							sx={{ width: { lg: '30%', xs: '90%' }, color: 'gray' }}
							onChange={(e) => setPassword(e.target.value)}
							value={password}
							type="password"
							InputProps={{
								style: {
									borderRadius: '50px',
								},
							}}
						/>
						<Typography
							sx={{
								fontSize: '12px',
								marginTop: '8px',
								textDecoration: 'underline',
								width: { lg: '30%', xs: '90%' },
								textAlign: 'left',
								paddingLeft: '10px',
								cursor: 'pointer',
							}}
							onClick={handleSubmitEmail}
						>
							Forgot Password ?
						</Typography>
						<StyledButton onClick={handleSubmit}>Login</StyledButton>

						<BottomLine
							sx={{
								fontSize: { xs: '12px', lg: '12px' },
								padding: { xs: '5rem 2.6rem 2.5rem', lg: '2rem 2rem 2rem' },
							}}
						>
							By clicking Continue/Login above, you acknowledge that you have
							read and understood, and you agree to the <br />
							<Link
								style={{ textDecoration: 'none', color: 'gray' }}
								to="/terms-and-conditions"
							>
								<span style={{ textDecoration: 'underline', color: 'gray' }}>
									{' '}
									Terms and Conditions
								</span>{' '}
								&nbsp;&nbsp;& &nbsp;{' '}
							</Link>
							<Link style={{ textDecoration: 'none' }} to="/privacy-policy">
								<span style={{ textDecoration: 'underline', color: 'gray' }}>
									Privacy Policy
								</span>
							</Link>
						</BottomLine>

						<Typography fontSize="12px">
							Powered By &nbsp;
							{/* <a style={{ color: 'gray' }} href="https://sustent.in"> */}
							SUSTENT
							{/* </a> */}
						</Typography>
					</InputGroup>
				</Box>
			</MainContainer>
		</Box>
	);
};

export default AdminLogin;
