import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Box, Typography, useMediaQuery } from '@mui/material';

const PrivacyPolicy = () => {
	const isMobile = useMediaQuery('(max-width:600px)');
	const [accordionNum, setAccordionNum] = React.useState(0);

	const handleAccordionClick = (num) => {
		if (num == accordionNum) {
			setAccordionNum(0);
		} else {
			setAccordionNum(num);
		}
	};

	return (
		<div>
			<Navbar />
			<Box
				backgroundColor="#E9FAFF"
				paddingTop={isMobile ? '50px' : '100px'}
				paddingBottom="50px"
			>
				<Typography
					variant="h4"
					fontWeight="bold"
					align="center"
					color="#294D8F"
					sx={{ mb: 2 }}
				>
					{/* Enabler Privacy Policy */}
					Privacy Policy
				</Typography>
				<Typography
					fontWeight="bold"
					variant="subtitle2"
					align="center"
					color="#575757"
				>
					This legal disclosure document by the owner of this website is meant
					to inform all visitors coming to this website about the privacy
					policy.
				</Typography>
			</Box>
			<Box textAlign="left" padding={isMobile ? '20px ' : '40px 255px'}>
				<Typography
					onClick={() => handleAccordionClick(1)}
					cursor="pointer"
					variant="h6"
					fontWeight="bold"
					color="#294D8F"
					sx={{ mb: 1, cursor: 'pointer' }}
				>
					<span
						style={{
							display: 'inline-block',
							transform: accordionNum == 1 ? 'rotate(90deg)' : 'rotate(360deg)',
						}}
					>
						&gt;&gt;
					</span>{' '}
					Overview
				</Typography>
				{accordionNum == 1 && (
					<Typography
						variant="body1"
						color="#4E4E4E"
						sx={{ mb: 2, mt: 1.8 }}
						fontSize="14px"
						fontFamily="Roboto"
					>
						The website is owned and managed by SUSTENT Consulting Private
						Limited and detailed contact information is listed on the website
						under the Contact Us tab. For any concerns or grievances about the
						website you can contact the website manager at info@sustent.in.
						<br />
						<br /> The website offers a suite of sustainability related
						professional services to registered users from business enterprises
						and other entities. The services are in many formats, for example
						self-help eLearning modules, assessment tools, calculator and
						estimation tools.
						<br />
						<br />
						The services are for professional users and for professional use
						only.
					</Typography>
				)}
				<div
					style={{
						height: '1px',
						backgroundColor: '#9FB0B53B',
						width: '100%',
						marginBottom: '15px',
					}}
				></div>
				<Typography
					onClick={() => handleAccordionClick(2)}
					variant="h6"
					fontWeight="bold"
					color="#294D8F"
					sx={{ mb: 1, cursor: 'pointer' }}
				>
					<span
						style={{
							display: 'inline-block',
							transform: accordionNum == 2 ? 'rotate(90deg)' : 'rotate(360deg)',
						}}
					>
						&gt;&gt;
					</span>{' '}
					What data we collect
				</Typography>
				{accordionNum == 2 && (
					<Typography
						variant="body1"
						color="#4E4E4E"
						sx={{ mb: 2, mt: 1.8 }}
						fontSize="14px"
						fontFamily="Roboto"
					>
						As an unregistered user, you can browse the website content which is
						open to unregistered users without telling us who you are or
						revealing any personal information about yourself.
						<br />
						<br /> An exception to this is when you contact us using the email
						id contact information provided on the website. In such interaction
						we will need your email ID and name to respond to your query by
						email. <br /> <br />
						When you browse this website, we collect some data relating to
						browsing activity. Some examples of such data are your IP address
						(but not your name or any other personal information), browser used
						and browsing duration.
						<br />
						<br /> We use standard features of Google Analytics to collect and
						review such data.
						<br />
						<br /> This information helps us in many ways to improve your
						experience on the website.
						<br />
						<br /> We do not share or sell the personal data to anyone.
						<br />
						<br /> As a registered user, you can browse content for which you
						have been given access by the website manager/administrator.
						<br />
						<br /> As a registered user, once you log in to your user account
						and browse this website, we collect the same data as specified above
						in this policy.
						<br />
						<br /> Additionally, as a registered user when you use specific
						services and tools for which you have been granted access, you may
						need to enter some data for example in a calculator or a performance
						monitoring tool or a planning tool. In all such cases, depending
						upon the functionality of the service or tool we may be saving some
						data either temporarily or permanently but in no case the saved data
						is used for any other purpose except to ensure correct functioning
						of the service or tool and such data is not shared or made visible
						to any third party.
					</Typography>
				)}
				<div
					style={{
						height: '1px',
						backgroundColor: '#9FB0B53B',
						width: '100%',
						marginBottom: '15px',
					}}
				></div>
				<Typography
					onClick={() => handleAccordionClick(3)}
					cursor="pointer"
					variant="h6"
					fontWeight="bold"
					color="#294D8F"
					sx={{ mb: 1, cursor: 'pointer' }}
				>
					<span
						style={{
							display: 'inline-block',
							transform: accordionNum == 3 ? 'rotate(90deg)' : 'rotate(360deg)',
						}}
					>
						&gt;&gt;
					</span>{' '}
					General disclaimer
				</Typography>
				{accordionNum == 3 && (
					<Typography
						variant="body1"
						color="#4E4E4E"
						sx={{ mb: 2, mt: 1.8 }}
						fontSize="14px"
						fontFamily="Roboto"
					>
						User data is stored on cloud servers maintained by the hosting
						provider Amazon Web Services (AWS) and secured by them as per their
						security protocol and safeguards. <br />
						<br /> We may disclose, without prior notification, approval or
						consent by a user, to tax authorities, local or governmental
						authorities and courts any confidential information that is required
						to be disclosed by law pertaining to use of this website.
						<br />
						<br /> We are not responsible in any way for your privacy on all
						other websites from where you may come to visit this website or may
						go to after your visit to this website. Also, there maybe links to
						or from other sites on this website and we cannot guarantee their
						security and fidelity. <br />
						<br />
						SUSTENT Consulting Private Limited reserve the right to amend this
						privacy policy to keep it in line with the latest legal requirements
						or to adjust it to reflect changes in the content of the website.
						The latest version of our policy will apply to any further visits.
						As on date the latest version is 1 September 2023.
					</Typography>
				)}
				<div
					style={{
						height: '1px',
						backgroundColor: '#9FB0B53B',
						width: '100%',
						marginBottom: '15px',
					}}
				></div>
			</Box>
		</div>
	);
};

export default PrivacyPolicy;
