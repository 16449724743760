import { createTheme } from '@mui/material';

export function createUserBasedtheme(color) {
	return createTheme({
		palette: {
			primary: {
				main: color,
			},
			secondary: {
				main: color,
			},
		},
		typography: {
			allVariants: {
				color: '#4A4A4A',
			},
		},
		components: {
			MuiInput: {
				styleOverrides: {
					root: {
						'&:before': {
							borderBottom: '1px solid white',
						},
						'&:hover:not(.Mui-disabled, .Mui-error):before': {
							borderBottom: '2px solid white',
						},
						'&.Mui-focused:after': {
							borderBottom: '1.5px solid white',
						},
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					notchedOutline: {
						borderColor: 'gray',
					},
					// root: {
					// 	[`&:hover .${outlinedInputClasses.notchedOutline}`]: {
					// 		borderColor: color,
					// 	},
					// 	[`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
					// 		borderColor: color,
					// 	},
					// },
				},
			},
		},
	});
}
