import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
// import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
import { GlobalProvider } from './context/GlobalState';
//redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './components/App';

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<GlobalProvider>
				<Suspense fallback={<div>Loading...</div>}>
					<BrowserRouter>
						<App />
						<ToastContainer />
					</BrowserRouter>
				</Suspense>
			</GlobalProvider>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);
