import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';
import jwt from 'jwt-decode';

const initialState = {
	admin: { messageCounter: 0 },
	auth: {
		loggedIn: false,
		username: '',
		usertype: '',
		userId: '',
		organization: '',
	},
};

if (localStorage.getItem('token')) {
	const token = JSON.parse(localStorage.getItem('token'));
	const { data: usertype } = jwt(token);

	initialState.auth.loggedIn = usertype.loggedIn;
	initialState.auth.username = usertype.username;
	initialState.auth.usertype = usertype.usertype;
	initialState.auth.userId = usertype.userId;
	initialState.auth.organization = usertype.organization;
	initialState.userAgent = JSON.parse(localStorage.getItem('userAgent'));
	// initialState.admin.messageCounter = 0;
	if (localStorage.getItem('reapToken')) {
		const reapToken = JSON.parse(localStorage.getItem('reapToken'));
		initialState.auth.loggedIn = reapToken.loggedIn;
		initialState.auth.username = reapToken.username;
		initialState.auth.usertype = reapToken.usertype;
		initialState.auth.userId = reapToken.userId;
	}

	// const resources = usertype?.resources;
}

//create Context
export const GlobalContext = createContext(initialState);

//Provider Component
export const GlobalProvider = ({ children }) => {
	const [state, dispatch] = useReducer(AppReducer, initialState);

	//Actions
	const userLogin = (userData) => {
		dispatch({
			type: 'USER_LOGIN',
			payload: userData,
		});
	};

	const setMessageCounter = (num) => {
		dispatch({
			type: 'UPDATE QUERY UNSEEN COUTNER',
			payload: num,
		});
	};

	return (
		<GlobalContext.Provider
			value={{
				auth: state.auth,
				userLogin,
				setMessageCounter,
			}}
		>
			{children}
		</GlobalContext.Provider>
	);
};
