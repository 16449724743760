import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoRoute = () => {
	const navigate = useNavigate();

	return (
		<Box
			display="flex"
			justifyContent="center"
			flexDirection="column"
			alignContent="center"
			height="80vh"
			textAlign="center"
		>
			<Typography variant="h1" gutterBottom>
				404 Not found
			</Typography>
			<Button
				variant="contained"
				onClick={() => navigate(-1)}
				style={{ width: '100px' }}
				sx={{ mx: 'auto' }}
			>
				Go Back
			</Button>
		</Box>
	);
};

export default NoRoute;
