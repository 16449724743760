import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useGetAllToolsQuery } from '../../store/middleware/common';
import PagesHomeComponent from '../../components/PageHome/PagesHomeComponent';
import RecoWizard from './RecoWizard';
import { useLocation } from 'react-router-dom';

const SystemizedServicesHome = () => {
	const { data, error, isLoading } = useGetAllToolsQuery();
	if (isLoading) {
		return 'Loading...';
	}
	if (error) {
		return 'Error';
	}
	const location = useLocation();

	return (
		<>
			{/* <SystemizedServicesSidebar /> */}
			<Routes>
				<Route
					index
					element={
						<PagesHomeComponent
							data={data?.tools?.filter(
								(tool) => tool.type == 'Systemized Services'
							)}
						/>
					}
				/>
				<Route path="/reco-wizard" element={<RecoWizard />} />
				<Route path="*" element={<Navigate replace to="/login" />} />
			</Routes>
		</>
	);
};

export default SystemizedServicesHome;
