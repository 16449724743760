import { useMediaQuery, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

const RecoWizard = () => {
	const auth = useSelector((state) => state.auth);
	const [, setCookie] = useCookies(['authData']); // Define the cookie name
	const [iframeHeight, setIframeHeight] = useState('700px');
	const [loader, setLoader] = useState(true);

	useEffect(() => {
		setCookie('authData', auth.email, {
			path: '/',
			domain: process.env.REACT_APP_RECO_WIZARD_DOMAIN,
			secure: process.env.REACT_APP_RECO_WIZARD_SECURE,
			sameSite: 'none',
		});
		// Listen for messages from the child component
		window.addEventListener('message', (event) => {
			if (event.data.type === 'CONTENT_HEIGHT') {
				setIframeHeight(`${event.data.height}px`);
			}
		});

		setTimeout(() => {
			setLoader(false);
		}, 4000);
	}, []);

	const isMobile = useMediaQuery('(max-width:600px)');

	return (
		<div style={{ marginTop: isMobile ? '-80px' : '-70px' }}>
			{loader ? (
				<div
					style={{
						height: '80vh',
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					id="iframe"
				>
					<CircularProgress size="4rem" />
				</div>
			) : (
				<iframe
					// ref={iFrameRef}
					// src={`https://recowizard.sustent.in`}
					src={process.env.REACT_APP_RECO_WIZARD_IFRAME_SRC}
					title="Reco Wizard"
					id="iframe"
					width="100%"
					height={iframeHeight}
					allowFullScreen
					sandbox="allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-downloads allow-forms" // Add
					frameBorder="0"
					scrolling={isMobile ? 'auto' : 'no'}
					loading="lazy"
					name="reco-wizard"
					style={{ visibility: loader ? 'hidden' : 'visible' }}
					// onLoad={handleIframeLoad} // Call handleIframeLoad when iframe loads
				/>
			)}
		</div>
	);
};

export default RecoWizard;
