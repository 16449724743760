import { styled } from '@mui/system';
import backgroundImage from '../assets/images/homepage/grayBackground.png';
import whiteBackground from '../assets/images/homepage/whiteBackground.png';

export const Html = styled('html')({
	margin: '0',
	padding: '0',
	boxSizing: 'border-box',
	overflowX: 'hidden',
	overflowY: 'scroll',
});

export const Body = styled('body')({
	margin: '0',
	padding: '0',
	boxSizing: 'border-box',
	overflowX: 'hidden !important',
	overflowY: 'scroll',
});

export const MainContainer = styled('div')({
	margin: '0',
	padding: '0',
	boxSizing: 'border-box',
	overflowX: 'hidden !important',
});

// Optional: If you need to use background images for specific sections
export const SectionOne = styled('div')({
	backgroundImage: `url(${backgroundImage})`,
});

export const SectionOneInner = styled('div')({
	backgroundImage: `url(${whiteBackground})`,
});
