import {
	Box,
	Button,
	Stack,
	Grid,
	keyframes,
	styled,
	Typography,
} from '@mui/material';
import grayBackground from '../../assets/images/homepage/grayBackground.png';
import borderRadiusWhite from '../../assets/images/homepage/whiteBackground.png';
import curvedBackgroundSmall from '../../assets/images/homepage/curvedBackgroundSmall.png';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

export const HeaderContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	padding: '15px 90px',
	height: 75,
	backgroundColor: '#ffffff',
	borderBottom: '1px solid lightgray',
	[theme.breakpoints.down('sm')]: {
		padding: '10px 20px',
		height: 55,
	},
}));

export const CircleImageContainer = styled(Box)(({ theme }) => ({
	height: '540px',
	paddingRight: '35px',
	paddingTop: '10px',
	paddingBottom: '20px',
	[theme.breakpoints.down('sm')]: {
		height: '400px',
		paddingRight: '0px',
		paddingTop: '25px',
	},
}));

export const WhiteBackground = styled(Box)(({ theme }) => ({
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100% 100%',
	backgroundImage: `url(${borderRadiusWhite})`,
	[theme.breakpoints.down('sm')]: {
		backgroundImage: `url(${curvedBackgroundSmall})`,
	},
}));

export const RoundImage = styled(Box)(({ theme }) => ({
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	[theme.breakpoints.down('sm')]: {
		textAlign: 'center',
	},
}));

const moveAndDisappear = keyframes`
  0% {
    top: 40%;
    left: 43%;
    opacity: 1;
  }
  50% {
    top: 50%;  /* Move down */
    left: 30%;  /* Move to the left */
    opacity: 0; /* Fade out */
  }
   100% {
    top: 80%;  /* Move down */
    left: 20%;  /* Move to the left */
    opacity: 0; /* Fade out */
  }
`;

const moveAndDisplay = keyframes`
  0% {
    top: 30%;
    left: 63%;
    opacity: 0;
  }
  50% {
    top: 43%;  /* Move down */
    left: 35%;
    opacity: 1; /* Fade out */
  }
   100%{
    top: 43%;  /* Move down */
    left: 35%;  /* Move to the left */
    opacity: 1; /* Fade out */
  }
`;

export const StyledGridItem = styled(Grid)(({ theme }) => ({
	[theme.breakpoints.down('sm')]: {
		margin: 'auto',
	},
}));

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
	display: 'flex', // Ensures flexbox behavior
	justifyContent: 'start',
	flexDirection: 'row',
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column-reverse',
		justifyContent: 'center',
	},
}));

export const StyleImageContainer = styled(Box)(({ theme }) => ({
	borderRadius: '50%',
	width: '100%',
	maxWidth: '450px',
	position: 'relative',
	boxSizing: 'border-box',
	'&:hover .arrow': {
		animation: `${moveAndDisappear} 0.75s forwards`, // Apply the animation on hover
	},
	'&:hover .text': {
		animation: `${moveAndDisplay} 0.75s forwards`, // Apply the animation on hover
		display: 'block',
	},
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
	position: 'absolute',
	top: '28%',
	left: '47%',
	zIndex: 80,

	color: '#294D8F',
	transition: 'all 1s ease-in-out', // Smooth transition,
	display: 'none',
	fontsize: 18,
	fontWeight: 600,
	// Apply a specific class name directly to target it in the hover state of StyledImageBox
}));

export const StyledImageBox = styled(Box)(({ theme }) => ({
	width: '100%',
	maxWidth: '450px',
	borderRadius: '50%',
	boxSizing: 'border-box',
	position: 'relative', // Ensure positioning context for the icon
	'&:hover': {
		cursor: 'pointer',
		opacity: 0.7,
		margin: theme.breakpoints.up('md') ? 'auto' : 'inherit',
		outline: '6px solid green', // Use outline instead of border
		outlineOffset: '-5px', // Move the outline inward,
		// Target the icon with the "arrow" class on hover
	},
	[theme.breakpoints.down('md')]: {
		width: '100%',
		maxWidth: '300px',
	},
}));

export const StyledPlayIcon = styled(PlayCircleOutlineIcon)(({ theme }) => ({
	position: 'absolute',
	top: '40%',
	left: '43%',
	zIndex: 80,
	fontSize: '70px',
	color: '#294D8F',
	transition: 'all 1s ease-in-out', // Smooth transition,
	fontWeight: 500,
	[theme.breakpoints.down('sm')]: {
		top: '37%',
		left: '40%',
	},
	// Apply a specific class name directly to target it in the hover state of StyledImageBox
}));

export const FiveClickImageHomeContainer = styled(Box)(({ theme }) => ({
	paddingTop: '100px',
	backgroundColor: 'white',
	paddingBottom: '100px',
	[theme.breakpoints.down('sm')]: {
		paddingTop: '60px',
		paddingBottom: '50px',
	},
}));

export const FiveImagesContainer = styled(Box)(({ theme }) => ({
	textAlign: 'center',
	paddingBottom: '25px',
	display: 'flex',
	[theme.breakpoints.down('sm')]: {
		paddingBottom: '0px',
	},
}));

export const PlainImage = styled('img')(({ theme }) => ({
	cursor: 'pointer',
	width: '200px',
	height: '300px',
	[theme.breakpoints.down('sm')]: {
		width: '115px',
		height: '180px',
	},
}));

export const GridItem = styled(Box)({
	margin: '8px',
});

export const Line = styled(Box)(({ theme }) => ({
	height: '2px',
	width: '83%',
	backgroundColor: '#294D8F',
	position: 'relative',
	[theme.breakpoints.down('sm')]: {
		width: '90%',
		marginTop: '20px',
	},
}));

export const VerticalLine = styled(Box)(({ theme }) => ({
	position: 'absolute',
	height: '2px',
	width: '66px',
	backgroundColor: '#294D8F',
	transform: 'rotate(90deg)',
	bottom: '33px',
	[theme.breakpoints.down('sm')]: {
		width: '31px',
		bottom: '17px',
	},
}));

export const FiveImageButton = styled(Button)(({ theme }) => ({
	backgroundColor: '#00AA45',
	padding: '7px 10px',
	color: 'white',
	display: 'flex',
	gap: '20px',
	justifyContent: 'space-between',
	border: '1px solid #00AA45',
	borderRadius: '15px',
	marginTop: '15px',
	cursor: 'pointer',
	whiteSpace: 'nowrap',
	[theme.breakpoints.down('sm')]: {
		width: '100%',
		fontSize: '12px',
		padding: '7px 12px',
	},
}));

export const ImageDescription = styled(Box)({
	marginLeft: '178px',
	marginTop: '25px',
});

export const Links = styled('a')({
	textDecoration: 'none',
	color: 'inherit',
});

export const ButtonRightIcon = styled(Button)({
	backgroundColor: 'white',
	borderRadius: '50%',
	width: '50px',
	color: 'green',
});

export const FiveImageDescription = styled(Box)(({ theme }) => ({
	height: '100px',
	minHeight: '100px',
	overflow: 'clip',
	fontSize: '10px',
	color: '#4E4E4E',
	lineHeight: 1.2,
	[theme.breakpoints.down('sm')]: {
		textAlign: 'left',
		textOverflow: 'ellipsis',
	},
}));

export const FiveImageHeading = styled(Box)(({ theme }) => ({
	height: '72px',
	maxHeight: '72px',
	overflow: 'clip',
	color: '#294D8F',
	fontWeight: '900',
	[theme.breakpoints.down('sm')]: {
		fontSize: '14px',
		height: '62px',
		textAlign: 'left',
	},
}));

export const DescriptionContainer = styled(Box)(({ theme }) => ({
	width: '100%',
	height: '300px',
	overflow: 'none',
	display: 'grid',
	gridTemplateColumns: '1fr 1fr 1fr 1fr',
	paddingLeft: '10px',
	[theme.breakpoints.down('sm')]: {
		display: 'flex',
		flexWrap: 'nowrap',
	},
}));

export const PaperContainer = styled(Box)(({ theme }) => ({
	backgroundImage: `url(${grayBackground})`,
	padding: '100px 30px',
	textAlign: 'center',
	[theme.breakpoints.down('sm')]: {
		padding: '60px 30px',
	},
}));

export const ResourceContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '100px',
	marginTop: '60px',
	[theme.breakpoints.down('sm')]: {
		flexWrap: 'wrap',
		gap: '20px',
	},
}));

export const RequestAccessContainer = styled(Stack)(({ theme }) => ({
	marginTop: '20px',
	display: 'flex',
	justifyContent: 'center',
	padding: '30px 500px',
	[theme.breakpoints.down('sm')]: {
		padding: '20px 20px',
	},
}));

export const FooterContainer = styled(Box)(({ theme }) => ({
	padding: '30px 100px 20px 100px',
	[theme.breakpoints.down('sm')]: {
		padding: '20px',
	},
}));

export const FooterInnerContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
});
