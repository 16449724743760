import axios from 'axios';

const instance = axios.create({
	// .. where we make our configurations
	baseURL: process.env.REACT_APP_API_KEY,
	// baseURL: 'http://localhost:3002/',
});

// Where you would set stuff like your 'Authorization' header, etc ...
instance.defaults.headers.common['authorization'] = `Bearer ${JSON.parse(
	localStorage.getItem('token')
)}`;

// Also add/ configure interceptors && all the other cool stuff

export default instance;
