import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import useStyles from './StyledComponents';
import logo from '../../assets/images/homepage/logoNew.png';
import { Link, useNavigate } from 'react-router-dom';
import logoNew from '../../assets/images/homepage/logoNew.png';
import loginButton from '../../assets/images/homepage/loginButton.png';
import { HeaderContainer } from '../Home/StyledComponents';

function NavbarTbw(props) {
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width:600px)');

	return (
		<HeaderContainer>
			<img
				src={logoNew}
				height={isMobile ? '35px' : '45px'}
				width={isMobile ? '70px' : '100px'}
				style={{ cursor: 'pointer' }}
				onClick={() => navigate('/')}
				alt="logo"
			/>
		</HeaderContainer>
	);
}

export default NavbarTbw;
