import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	eLearningSelection: '',
};

const userPersonalizationSlice = createSlice({
	name: 'userPersonalization',
	initialState,
	reducers: {
		setELearningSelection(state, action) {
			state.eLearningSelection = action.payload;
		},
	},
});

export const { setELearningSelection } = userPersonalizationSlice.actions;
export default userPersonalizationSlice.reducer;
