import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customFetchBaseQuery from '../../components/utils/customBaseQuery';

export const commonApi = createApi({
	reducerPath: 'commonApi',
	baseQuery: customFetchBaseQuery('common'),
	// baseQuery: fetchBaseQuery({
	// 	baseUrl: `${process.env.REACT_APP_API_KEY}/common`,
	// 	prepareHeaders: (headers, { getState }) => {
	// 		const token = getState().auth.token;
	// 		if (token) {
	// 			headers.set('Authorization', `Bearer ${token}`);
	// 		}
	// 		return headers;
	// 	},
	// 	credentials: 'include',
	// }),
	tagTypes: ['commonApi'],

	endpoints: (builder) => ({
		getAllTools: builder.query({
			query: () => '/get-all-tools', // This is the endpoint for the GET request
		}),
		getAllCountries: builder.query({
			query: () => '/get-all-countries', // This is the endpoint for the GET request
		}),
		getUserTools: builder.query({
			// The queryFn receives an object with args
			query: (args) => `/get-user-tools/${args.id}`,
			providesTags: ['userTools'],
		}),
		getCountryAndRegion: builder.query({
			query: () => '/get-country-and-region', // This is the endpoint for the GET request
		}),
	}),
});

export const {
	useGetAllToolsQuery,
	useGetAllCountriesQuery,
	useGetUserToolsQuery,
	useGetCountryAndRegionQuery,
} = commonApi;
