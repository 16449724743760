import { styled } from '@mui/system';
import { Box, TextField, Button, Typography, Stack } from '@mui/material';

export const MainContainer = styled(Stack)({
	color: '#909090',
	textAlign: 'center',
	boxSizing: 'border-box',
	overflowX: 'hidden',
});

export const GraySection = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	justifyContent: 'center',
	textAlign: 'center',
	[theme.breakpoints.down('sm')]: {
		textAlign: 'center',
		padding: '0 2rem',
		backgroundColor: 'white',
		border: 'none',
		height: '28vh',
		marginBottom: '20px',
	},
}));

export const InputGroup = styled(Box)({
	marginTop: '45px',
	display: 'flex',
	justifyContent: 'center',
	flexDirection: 'column',
	alignItems: 'center',
});

export const StyledInput = styled(TextField)({
	width: '30%',
	borderRadius: '40px',
});

export const StyledButton = styled(Button)({
	borderRadius: '15px',
	padding: '0.2rem 3rem',
	backgroundColor: '#294D8F',
	color: 'white',
	marginTop: '1.5rem',
	border: 'none',
	marginBottom: '2rem',
	border: '1px solid #294D8F',
	cursor: 'pointer',
	textTransform: 'capitalize',
	'&:hover': {
		backgroundColor: '#294D8F',
		color: 'white',
		border: '1px solid #294D8F',
	},
});

export const BottomLine = styled(Typography)({
	marginTop: '60px',
	fontSize: '12px',
});

export const ForgotPassword = styled(Typography)({
	textAlign: 'start !important',
});

export const CustomNotchedOutline = styled(TextField)(({ theme }) => ({
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: theme.palette.secondary.main,
		},
	},
}));
