import {
	Box,
	Typography,
	Divider,
	Container,
	useMediaQuery,
	Grid,
} from '@mui/material';
import React, { useEffect } from 'react';
import logoNew from '../../assets/images/homepage/logoNew.png';

import { Link } from 'react-router-dom';
import AnimatedButton from './AnimatedButton';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const Footer = () => {
	const isMobile = useMediaQuery('(max-width:900px)');

	return (
		<Box
			style={{
				backgroundColor: '#294D8F',
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column',
			}}
			id="contact-us"
		>
			<Container
				maxWidth="xl"
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					pt: 2,
				}}
			>
				<Box>
					<img
						alt="logo"
						src={logoNew}
						width={isMobile ? '70px' : '100px'}
						height={isMobile ? '35px' : '50px'}
					/>
				</Box>

				<AnimatedButton
					bgColor="#ffffff"
					arrowBgColor="#294D8F"
					arrowColor="white"
					textColor="#294D8F"
					text="Contact us"
					translateXIcon={isMobile ? 91 : 105}
					translateXText={isMobile ? -20 : -26}
					hoverBgColor={'#294D8F'}
					hoverTextColor={'#ffffff'}
					hoverArrowColor={'#294D8F'}
					hoverArrowBgColor={'#ffffff'}
					borderColor={'white'}
				/>
			</Container>
			<Container
				maxWidth={'lg'}
				sx={{ textAlign: 'center', pb: { xs: 5, md: 7, lg: 10 } }}
			>
				<Typography
					fontFamily="Roboto"
					variant="subtitle1"
					color="white"
					pb={2}
					fontWeight={600}
					fontSize={30}
				>
					Get in Touch
				</Typography>
				<Typography
					fontFamily="Roboto"
					variant="subtitle1"
					color="white"
					fontSize={14}
				>
					Unsure of what to do? Our team can you figure out your sustainability
					strategy
				</Typography>
			</Container>

			<Container
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
				}}
				maxWidth={'md'}
			>
				<Grid container spacing={isMobile ? 3 : 5}>
					<Grid item xs={6} md={3}>
						<img
							src="./homepage/location.png"
							alt=""
							style={{ width: isMobile ? '30px' : '40px' }}
						/>
						<Typography
							fontFamily="Roboto"
							variant="subtitle1"
							color="#E1FFA6"
							fontSize={'18px'}
						>
							Location
						</Typography>
						<Typography
							fontFamily="Roboto"
							variant="subtitle1"
							color="white"
							fontSize={isMobile ? '10px' : '12px'}
						>
							412A, Suncity Trade Tower
							<br />
							Sector,21 Gurugram
							<br />
							India - 122016
							<br />
							(mon-fri, 9am-5pm )<br />
						</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<img
							src="./homepage/email.png"
							alt=""
							style={{ width: isMobile ? '30px' : '40px' }}
						/>
						<Typography
							fontFamily="Roboto"
							variant="subtitle1"
							color="#E1FFA6"
							fontSize={18}
							sx={{ mt: 1.5 }}
						>
							E-mail
						</Typography>
						<Typography
							fontFamily="Roboto"
							variant="subtitle1"
							color="white"
							pb="10px"
							fontSize={isMobile ? '10px' : '12px'}
						>
							info@sustent.in
						</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<img
							src="./homepage/web.png"
							alt=""
							style={{ width: isMobile ? '30px' : '40px' }}
						/>
						<Typography fontFamily="Roboto" variant="subtitle1" color="#E1FFA6">
							Website
						</Typography>
						<Typography
							fontFamily="Roboto"
							variant="subtitle1"
							color="white"
							pb="10px"
							fontSize={isMobile ? '10px' : '12px'}
						>
							www.sustent.in
						</Typography>
					</Grid>
					<Grid item xs={6} md={3}>
						<img
							src="./homepage/phone.png"
							alt=""
							style={{ width: isMobile ? '30px' : '40px' }}
						/>
						<Typography fontFamily="Roboto" variant="subtitle1" color="#E1FFA6">
							Phone
						</Typography>
						<Typography
							fontFamily="Roboto"
							variant="subtitle1"
							color="white"
							fontSize={isMobile ? '10px' : '12px'}
						>
							+91 124 411 6555
						</Typography>
					</Grid>
				</Grid>
			</Container>

			<Container sx={{ marginTop: 'auto' }} maxWidth={'lg'}>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'end'}
					pb={2}
				>
					<Box>
						<Box textAlign={'start'}>
							<img
								alt="logo"
								src={logoNew}
								width={isMobile ? '70px' : '100px'}
								height={isMobile ? '35px' : '50px'}
							/>
						</Box>
						<Typography
							fontFamily="Roboto"
							variant="subtitle1"
							color="white"
							fontSize={isMobile ? '10px' : '12px'}
						>
							Powered by SUSTENT Consulting
						</Typography>
					</Box>
					<Box display={isMobile ? 'none' : 'flex'}>
						<Link style={{ textDecoration: 'none' }} to="/privacy-policy">
							<Typography
								fontSize="14px"
								color="white"
								fontFamily="Roboto"
								fontWeight="bold"
								sx={{
									'&:hover': { cursor: 'pointer' },
									pr: 2,
									pt: 2,
								}}
							>
								Privacy Policy
							</Typography>
						</Link>
						<Link style={{ textDecoration: 'none' }} to="/terms-and-conditions">
							<Typography
								fontSize="14px"
								color="white"
								fontWeight="bold"
								textAlign="center"
								sx={{ '&:hover': { cursor: 'pointer' } }}
								pt={2}
							>
								Terms of Use
							</Typography>
						</Link>
					</Box>

					<Box textAlign={'center'}>
						<a href="#top" style={{ textDecoration: 'none' }}>
							<KeyboardDoubleArrowUpIcon
								sx={{ color: 'white', fontSize: 18 }}
							/>
							<Typography variant="subtitle1" color="white" fontSize={'12px'}>
								Back to top
							</Typography>
						</a>
					</Box>
				</Box>
				<Divider sx={{ backgroundColor: 'white', mb: 2 }} />
				<Box display={isMobile ? 'flex' : 'none'} justifyContent={'center'}>
					<Link style={{ textDecoration: 'none' }} to="/privacy-policy">
						<Typography
							fontSize="14px"
							color="white"
							fontFamily="Roboto"
							sx={{
								'&:hover': { cursor: 'pointer' },

								pr: 1,
							}}
						>
							Privacy Policy
						</Typography>
					</Link>
					<Link style={{ textDecoration: 'none' }} to="/terms-and-conditions">
						<Typography
							fontSize="14px"
							color="white"
							textAlign="center"
							sx={{ '&:hover': { cursor: 'pointer' } }}
						>
							| &nbsp;Terms of Use
						</Typography>
					</Link>
				</Box>
				<Box
					display="flex"
					justifyContent={isMobile ? 'center' : 'space-between'}
					color="white"
					marginTop={1}
				>
					<Typography
						fontFamily="Roboto"
						fontSize={isMobile ? '10px' : '12px'}
						pb={5}
						color={'white'}
					>
						<sup>&copy;</sup> 2023 enabler. All Rights Reserved.{' '}
					</Typography>
				</Box>{' '}
			</Container>
		</Box>
	);
};

export default Footer;
