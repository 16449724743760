import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import { authApi } from './middleware/authApi';
import { commonApi } from './middleware/common';
import { adminApi } from './middleware/adminApi';
import { queryManagerApi } from './middleware/queryManager';
import { contentManagerApi } from './middleware/contentManger';
import { newsApi } from './middleware/newsApi';
import userPersonalization from './reducers/userPersonalization';
import { satApi } from './middleware/satApi';

const rootReducer = combineReducers({
	[authApi.reducerPath]: authApi.reducer,
	[commonApi.reducerPath]: commonApi.reducer,
	[adminApi.reducerPath]: adminApi.reducer,
	[queryManagerApi.reducerPath]: queryManagerApi.reducer,
	[contentManagerApi.reducerPath]: contentManagerApi.reducer,
	[newsApi.reducerPath]: newsApi.reducer,
	[satApi.reducerPath]: satApi.reducer,
	auth: authReducer,
	userPersonalization: userPersonalization,
	// Add other slice reducers here
});

export default rootReducer;
