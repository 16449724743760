import {
	Stack,
	Typography,
	Box,
	TextField,
	CircularProgress,
} from '@mui/material';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import axiosConfig from '../../components/utils/axiosConfig';
import '../../fonts//roboto.css';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const ResetPassword = () => {
	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get('token');
	const navigate = useNavigate();
	const [password, setPassword] = useState('');
	const [viewPassword, setViewPassword] = useState(false);
	const [email, setEmail] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
	const [displayProgress, setDisplayProgess] = useState(false);

	function validatePassword(password) {
		const lengthRegex = /.{8,}/; // At least 8 characters
		const alphanumericRegex = /^(?=.*[A-Za-z])(?=.*\d)/; // A mix of alphabetical and numerical characters
		const specialCharRegex = /[!@#$]/; // At least one special character

		if (!lengthRegex.test(password)) {
			return 'Password must be at least 8 characters long';
		}

		if (!alphanumericRegex.test(password)) {
			return 'Password must contain both alphabetical and numerical characters';
		}

		if (!specialCharRegex.test(password)) {
			return 'Password must contain at least one special character (!, @, #, $)';
		}

		return ''; // Empty string indicates a valid password
	}

	const handleSubmit = async () => {
		// if (!email) {
		// 	toast.error('Please enter your registered email');
		// }
		if (!password) {
			toast.error('Please enter a new password');
		}
		if (!confirmPassword) {
			toast.error('Please confirm your new password');
		}
		if (password !== confirmPassword) {
			toast.error('Passwords dont match!');
			return;
		}

		const passwordTest = validatePassword(password);
		if (passwordTest === '') {
			try {
				const result = await axiosConfig.post('/users/reset-password', {
					password,
					token,
				});
				toast.success(
					'Password reset successfully. Please login with your new password'
				);
				setDisplayProgess(true);
				setTimeout(() => {
					setDisplayProgess(false);
					navigate('/login');
				}, 1000);
			} catch (error) {
				toast.error(error.response.data.msg);
				console.log(error);
			}
		} else {
			toast.error(passwordTest);
		}
	};
	return (
		<Stack
			display="flex"
			justifyContent="center"
			height="100vh"
			alignItems="center"
		>
			{displayProgress ? (
				<CircularProgress style={{ width: '100px', height: '100px' }} />
			) : (
				<>
					<Typography
						variant="h5"
						color="#294d8f"
						fontWeight="bold"
						style={{ fontFamily: 'Roboto,sans-serif' }}
						gutterBottom
					>
						Reset your password
					</Typography>
					<Typography
						variant="subtitle2"
						color="#707070"
						gutterBottom
						textAlign="center"
					>
						Create a new password to replace your old password
						<br /> generated by{' '}
						<i style={{ color: '#294d8f' }}>enabler &nbsp;</i>
					</Typography>
					<Box width="400px" marginTop="50px" marginBottom="30px">
						<Typography fontSize="12px" color="#707070" fontFamily="Roboto">
							New password should have
						</Typography>
						<Typography fontFamily="Roboto" fontSize="10px" color="#707070">
							At least 8 characters
						</Typography>
						<Typography color="#707070" fontFamily="Roboto" fontSize="10px">
							A mix of alphabetical and numerical characters
						</Typography>
						<Typography color="#707070" fontFamily="Roboto" fontSize="10px">
							At least one special character (!,@#,$,)
						</Typography>
					</Box>
					{/* <TextField
						label="Enter Your Registered Email"
						size="small"
						sx={{ width: { xs: '90%', lg: '30%' } }}
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						name="Username"
						InputProps={{
							style: {
								borderRadius: '50px',
							},
						}}
					/> */}

					<TextField
						label="Password"
						size="small"
						sx={{ width: { xs: '90%', lg: '30%' } }}
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						type={viewPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setViewPassword(!viewPassword)}
										onMouseDown={(event) => event.preventDefault()}
										edge="end"
									>
										{viewPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
							style: {
								borderRadius: '50px',
							},
						}}
					/>
					<TextField
						label="Confirm Password"
						style={{ marginTop: '15px' }}
						size="small"
						sx={{ width: { lg: '30%', xs: '90%' } }}
						onChange={(e) => setConfirmPassword(e.target.value)}
						value={confirmPassword}
						type={viewConfirmPassword ? 'text' : 'password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() => setViewConfirmPassword(!viewConfirmPassword)}
										onMouseDown={(event) => event.preventDefault()}
										edge="end"
									>
										{viewConfirmPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							),
							style: {
								borderRadius: '50px',
							},
						}}
					/>

					<button
						style={{
							borderRadius: '15px',
							padding: '0.7rem 3rem',
							backgroundColor: '#294D8F',
							color: 'white',
							marginTop: '1.5rem',
							marginBottom: '2rem',
							border: '1px solid #294D8F',
							cursor: 'pointer',
						}}
						onClick={handleSubmit}
					>
						Reset Password
					</button>
				</>
			)}
		</Stack>
	);
};

export default ResetPassword;
