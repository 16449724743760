import { Box, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import forgotPassword from '../../assets/images/forgotpassword/postIcon.png';
import '../../fonts/roboto.css';
import { toast } from 'react-toastify';
import axiosConfig from '../../components/utils/axiosConfig';
import {
	MainContainer,
	StyledButton,
	StyledInput,
} from '../Login/StyledComponents';

const ForgotPassword = ({ email, setRecoverEmail }) => {
	//state to toggle between letting user enter the email and showing the info about the next steps
	const [infoView, setInfoView] = useState(false);

	const handleSubmitEmail = async () => {
		if (!email) {
			toast.error('Please enter your email id');
			return;
		}
		setInfoView(true);
		try {
			const result = await axiosConfig.post('/users/forgot-password', {
				email,
			});
		} catch (error) {
			if (error.code == 'ERR_BAD_REQUEST') {
				toast.error(error.response.data.error);
			}
			if (error.code == 'ERR_BAD_RESPONSE') {
				toast.error(
					'There is a problem with the server at the moment. Please try again later'
				);
			}
			console.log(error);
		}
	};

	return (
		<Stack
			display="flex"
			justifyContent="center"
			height="100vh"
			alignItems="center"
		>
			{infoView ? (
				<>
					<Typography
						variant="h5"
						color="#294d8f"
						fontWeight="bold"
						style={{ fontFamily: 'Roboto,sans-serif' }}
						gutterBottom
					>
						Forgot your password?
					</Typography>
					<Typography variant="subtitle2" color="#707070" gutterBottom>
						<i style={{ color: '#294d8f' }}>enabler &nbsp;</i> has sent you an
						email to verfiy it's your account
					</Typography>
					<Box margin="50px auto">
						<img src={forgotPassword} />
					</Box>
					<Typography variant="subtitle2" color="#4E4E4E" gutterBottom>
						Click on the verification link sent to your linked email ID
					</Typography>
					<Typography
						color="#707070"
						variant="subtitle1"
						style={{ marginBottom: '30px' }}
					>
						{email}
					</Typography>
					<Typography
						fontWeight="bold"
						style={{ textDecoration: 'underline', cursor: 'pointer' }}
						color="#294D8F"
						onClick={handleSubmitEmail}
					>
						Resend
					</Typography>
				</>
			) : (
				<MainContainer>
					<Typography
						variant="h5"
						color="#294d8f"
						fontWeight="bold"
						style={{ fontFamily: 'Roboto,sans-serif' }}
						gutterBottom
						align="center"
						marginBottom="20px"
					>
						Forgot your password?
					</Typography>
					<Typography
						variant="h6"
						fontSize="16px"
						color="#294d8f"
						fontWeight="bold"
						style={{ fontFamily: 'Roboto,sans-serif' }}
						gutterBottom
					>
						Please enter your registered email id.
					</Typography>
					<Box textAlign="center">
						<StyledInput
							label="Enter Your Registered Email"
							size="small"
							sx={{ width: { xs: '90%', lg: '100%' } }}
							value={email}
							onChange={(e) => setRecoverEmail(e.target.value)}
							name="Username"
							InputProps={{
								style: {
									borderRadius: '50px',
								},
							}}
						/>
						<StyledButton onClick={handleSubmitEmail}>
							Send Reset Link
						</StyledButton>
					</Box>
				</MainContainer>
			)}
		</Stack>
	);
};

export default ForgotPassword;
