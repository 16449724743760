import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Box, Typography, useMediaQuery } from '@mui/material';

const TermsAndConditions = () => {
	const isMobile = useMediaQuery('(max-width:600px)');
	const [accordionNum, setAccordionNum] = React.useState(0);

	const handleAccordionClick = (num) => {
		if (num == accordionNum) {
			setAccordionNum(0);
		} else {
			setAccordionNum(num);
		}
	};

	return (
		<div>
			<Navbar />
			<Box
				backgroundColor="#E9FAFF"
				paddingTop={isMobile ? '50px' : '100px'}
				paddingBottom="50px"
			>
				<Typography
					variant="h4"
					fontWeight="bold"
					align="center"
					color="#294D8F"
					sx={{ mb: 2 }}
				>
					{/* Enabler Terms and Conditions */}
					Terms and Conditions
				</Typography>
				<Typography
					fontWeight="bold"
					variant="subtitle2"
					align="center"
					color="#575757"
				>
					This legal disclosure document by the owner of this website is meant
					to inform all visitors coming to this website the terms and conditions
					of use.
				</Typography>
			</Box>
			<Box textAlign="left" padding={isMobile ? '20px ' : '40px 255px'}>
				<Typography
					onClick={() => handleAccordionClick(1)}
					cursor="pointer"
					variant="h6"
					fontWeight="bold"
					color="#294D8F"
					sx={{ mb: 1, cursor: 'pointer' }}
				>
					<span
						style={{
							display: 'inline-block',
							transform: accordionNum == 1 ? 'rotate(90deg)' : 'rotate(360deg)',
						}}
					>
						&gt;&gt;
					</span>{' '}
					Overview
				</Typography>
				{accordionNum == 1 && (
					<Typography
						variant="body1"
						color="#4E4E4E"
						sx={{ mb: 2, mt: 1.8 }}
						fontSize="14px"
						fontFamily="Roboto"
					>
						The website is owned and managed by SUSTENT Consulting Private
						Limited and detailed contact information is listed on the website
						under the Contact Us tab. For any concerns or grievances about the
						website you can contact the website manager at info@sustent.in
						<br />
						<br /> The website offers a suite of sustainability related
						professional services to registered users from business enterprises
						and other entities. The services are in many formats, for example
						self-help eLearning modules, assessment tools, calculator and
						estimation tools.
						<br />
						<br />
						The services are for professional users and for professional use
						only.
						<br />
						<br /> The services, solutions and tools available on this website
						have been designed keeping in mind the specific needs of
						professional users from business enterprises but SUSTENT Consulting
						Private Limited does not warrant that the functionalities of the
						services, solutions and tools will meet all the requirements of a
						professional user.
					</Typography>
				)}
				<div
					style={{
						height: '1px',
						backgroundColor: '#9FB0B53B',
						width: '100%',
						marginBottom: '15px',
					}}
				></div>
				<Typography
					onClick={() => handleAccordionClick(2)}
					variant="h6"
					fontWeight="bold"
					color="#294D8F"
					sx={{ mb: 1, cursor: 'pointer' }}
				>
					<span
						style={{
							display: 'inline-block',
							transform: accordionNum == 2 ? 'rotate(90deg)' : 'rotate(360deg)',
						}}
					>
						&gt;&gt;
					</span>{' '}
					Condtions of USE and General Disclaimer
				</Typography>
				{accordionNum == 2 && (
					<Typography
						variant="body1"
						color="#4E4E4E"
						sx={{ mb: 2, mt: 1.8 }}
						fontSize="14px"
						fontFamily="Roboto"
					>
						By visiting this website, you agree to be bound by the terms and
						conditions of use and the associated privacy policy.
						<br />
						<br /> You will be able to access the website 24/7 on all days
						except when it may be temporarily down for maintenance or upgrades.
						SUSTENT Consulting Private Limited shall not be responsible for any
						internet related outages, delays, or other connectivity problems
						that may affect the smooth operation of the website. <br /> <br />
						You acknowledge and agree that no software is error-free and in some
						instances fixing an identified error may not be cost effective or
						warranted.
						<br />
						<br /> SUSTENT Consulting Private Limited, its employees and its
						related entities assume no responsibility for the accuracy,
						completeness or the quality of the content and information provided
						on this website. Therefore, any user of this website automatically
						and without any prejudice indemnifies SUSTENT Consulting Private
						Limited, its employees and its related entities against any
						liabilities or claims arising from use of this website and the
						content within. Any reliance you place on such information is
						therefore strictly at your own risk. SUSTENT Consulting Private
						Limited, its employees and its related entities will not be liable
						for any loss or damage including without limitation, indirect or
						consequential loss or damage, or any loss or damage whatsoever
						arising from loss of data or profits arising out of, or in
						connection with, the use of this website.
						<br />
						<br /> The entire website including the “enabler” and other
						branding, software, databases, user interface, content (excluding
						user input content), design and functionality of services, solutions
						and tools will at all times remain the exclusive intellectual
						property of SUSTENT Consulting Private Limited.
						<br />
						<br /> SUSTENT Consulting Private Limited reserve the right to amend
						this terms and conditions of use policy to keep it in line with the
						latest legal requirements or to adjust it to reflect changes in the
						content of the website. The latest version of our policy will apply
						to any further visits. As on date the latest version is 1 September
						2023.
					</Typography>
				)}
				<div
					style={{
						height: '1px',
						backgroundColor: '#9FB0B53B',
						width: '100%',
						marginBottom: '15px',
					}}
				></div>
				<Typography
					onClick={() => handleAccordionClick(3)}
					cursor="pointer"
					variant="h6"
					fontWeight="bold"
					color="#294D8F"
					sx={{ mb: 1, cursor: 'pointer' }}
				>
					<span
						style={{
							display: 'inline-block',
							transform: accordionNum == 3 ? 'rotate(90deg)' : 'rotate(360deg)',
						}}
					>
						&gt;&gt;
					</span>{' '}
					User Fees/Charges
				</Typography>
				{accordionNum == 3 && (
					<Typography
						variant="body1"
						color="#4E4E4E"
						sx={{ mb: 2, mt: 1.8 }}
						fontSize="14px"
						fontFamily="Roboto"
					>
						Based on the requirements and individual discussions (via email)
						with each registered user, the website manager/administrator will
						provide access to specific professional services/content of the
						website. Some of the professional services/content will have no
						charge whilst some will be chargeable. <br />
						<br /> Any charges to be paid by a registered user will be paid
						offline by the user to the owner of the website before any access is
						given by the website manager/administrator. There is no online
						functionality on the website to pay the charges for use of
						professional services/content.
						<br />
						<br /> SUSTENT Consulting Private Limited reserves the right to
						change the fees/charges applicable to users at any time without
						incurring any liability to the user.
					</Typography>
				)}
				<div
					style={{
						height: '1px',
						backgroundColor: '#9FB0B53B',
						width: '100%',
						marginBottom: '15px',
					}}
				></div>

				<Typography
					onClick={() => handleAccordionClick(4)}
					cursor="pointer"
					variant="h6"
					fontWeight="bold"
					color="#294D8F"
					sx={{ mb: 1, cursor: 'pointer' }}
				>
					<span
						style={{
							display: 'inline-block',
							transform: accordionNum == 4 ? 'rotate(90deg)' : 'rotate(360deg)',
						}}
					>
						&gt;&gt;
					</span>{' '}
					Security
				</Typography>
				{accordionNum == 4 && (
					<Typography
						variant="body1"
						color="#4E4E4E"
						sx={{ mb: 2, mt: 1.8 }}
						fontSize="14px"
						fontFamily="Roboto"
					>
						We use modern globally popular technologies to give our users a very
						safe and secure site. <br />
						<br /> To keep your data secure during transmission, we use
						contemporary encryption technology via HTTPS protocol but we make no
						warranties or representations of any kind that the digital
						information or services provided by this web site will be without
						any errors, uninterrupted and free of all types of computer viruses
						and related digital threats.
					</Typography>
				)}
				<div
					style={{
						height: '1px',
						backgroundColor: '#9FB0B53B',
						width: '100%',
						marginBottom: '15px',
					}}
				></div>

				<Typography
					onClick={() => handleAccordionClick(5)}
					cursor="pointer"
					variant="h6"
					fontWeight="bold"
					color="#294D8F"
					sx={{ mb: 1, cursor: 'pointer' }}
				>
					<span
						style={{
							display: 'inline-block',
							transform: accordionNum == 5 ? 'rotate(90deg)' : 'rotate(360deg)',
						}}
					>
						&gt;&gt;
					</span>{' '}
					External Links
				</Typography>
				{accordionNum == 5 && (
					<Typography
						variant="body1"
						color="#4E4E4E"
						sx={{ mb: 2, mt: 1.8 }}
						fontSize="14px"
						fontFamily="Roboto"
					>
						We are not responsible in any way for your privacy on all other
						websites from where you may come to visit this website or may go to
						after your visit to this website. Also there maybe links to or from
						other sites on this website. <br />
						<br /> The inclusion of any links does not necessarily imply a
						recommendation or endorsement of the views expressed within them.
						These are put in good faith and we disclaim any responsibility for
						the materials contained in any website linked to this site.
					</Typography>
				)}
				<div
					style={{
						height: '1px',
						backgroundColor: '#9FB0B53B',
						width: '100%',
						marginBottom: '15px',
					}}
				></div>
			</Box>
		</div>
	);
};

export default TermsAndConditions;
