export default (state, action) => {
	const { payload, type } = action;

	switch (type) {
		case 'USER_LOGIN':
			return {
				...state,
				auth: {
					...state.auth,
					username: payload.username,
					usertype: payload.usertype,
					loggedIn: payload.loggedIn,
					userId: payload.userId,
					organization: payload.organization,
				},
			};

		case 'UPDATE QUERY UNSEEN COUTNER': {
			return {
				...state,
				admin: {
					...state.admin,
					messageCounter: payload,
				},
			};
		}
		default:
			return state;
	}
};
