import { CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetUserToolsQuery } from '../../store/middleware/common';
import StyledCard from './StyledCard';
import EllipsisTypography from './EllipsisTypography';
import { CardMedia, CardMediaWrapper } from './StyledCardMedia';

const PagesHomeComponent = ({ data }) => {
	const reduxAuth = useSelector((state) => state.auth);

	const {
		data: assignedTools,
		isLoading: loading,
		isError,
	} = useGetUserToolsQuery({ id: reduxAuth.userId });

	if (loading) {
		return 'Loading...';
	}
	if (isError) {
		return 'An error occurred, please try again later';
	}

	const assignToolIdArray = assignedTools?.result[0]?.map(
		(tool) => tool.tool_id
	);

	if (!assignedTools) {
		return 'No tools assigned';
	}

	return (
		<>
			<Grid item xs={12} lg={12} md={12}>
				<Typography variant="h5" mb={5}>
					{data[0].type}
				</Typography>
			</Grid>
			<Grid container spacing={2}>
				{data
					?.filter((tools) => assignToolIdArray.includes(tools.id))
					.map((tool, index) => {
						return (
							<Grid
								item
								xs={12}
								sm={4}
								md={4}
								lg={3}
								key={index}
								// sx={{ width: 255, height: 315 }}
								// sx={{ opacity: assignToolIdArray.includes(tool.id) ? 1 : 0.3 }}
							>
								<StyledCard>
									<Link
										to={
											assignToolIdArray.includes(tool.id)
												? tool.path
												: '/no-access'
										}
										style={{
											textDecoration: 'none',
											listStyle: 'none',
											color: 'inherit',
										}}
									>
										<CardMediaWrapper className="mediaWrapper">
											<CardMedia
												className="media"
												src={'/no_image.png'}
												alt={'No Image'}
											/>
										</CardMediaWrapper>
										<CardContent>
											<EllipsisTypography>{tool.title}</EllipsisTypography>
											<EllipsisTypography
												variant="subtitle2"
												sx={{ color: '#00aa45' }}
											>
												{tool.description}
											</EllipsisTypography>
										</CardContent>
									</Link>
								</StyledCard>
							</Grid>
						);
					})}
			</Grid>
		</>
	);
};

export default PagesHomeComponent;
