// src/Components/StyledNewsCardMedia.js
import { styled } from '@mui/material/styles';

export const CardMedia = styled('img')({
	width: '50px',
	height: '50px',
	transition: 'transform 0.5s ease', // Smooth transition for scaling
	objectFit: 'contain', // Ensures the image covers the area without stretching
});

export const CardMediaWrapper = styled('div')({
	height: '120px',
	width: '100%',
	padding: '12px',
	borderBottom: '1px solid #777777',
	overflow: 'hidden', // Ensure the scaling image doesn't overflow the container
	transition: 'border-color 0.5s ease',
	marginBottom: '20px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});
