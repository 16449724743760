import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import rootReducer from './rootReducer';
import { authApi } from './middleware/authApi';
import { commonApi } from './middleware/common';
import { adminApi } from './middleware/adminApi';
import { queryManagerApi } from './middleware/queryManager';
import { contentManagerApi } from './middleware/contentManger';
import { newsApi } from './middleware/newsApi';
import { satApi } from './middleware/satApi';

// Configure Redux Persist
const persistConfig = {
	key: 'root', // Key for the persisted data in storage

	storage, // Storage engine (e.g., localStorage)
	// Optionally, whitelist or blacklist specific reducers
	// whitelist: ['auth'], // Whitelist specific reducers
	// blacklist: ['counter'], // Blacklist specific reducers
};
// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			// }),
		}).concat(
			authApi.middleware,
			commonApi.middleware,
			adminApi.middleware,
			queryManagerApi.middleware,
			contentManagerApi.middleware,
			newsApi.middleware,
			satApi.middleware
		),
});

// export default function () {
// 	return configureStore({
// 		reducer: persistedReducer,

// 	});
// }

const persistor = persistStore(store);

export { store, persistor };
