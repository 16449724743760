import React, { useState, useEffect, useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { MainContainer } from './StyledComponents';
import Login from '../pages/Login/Login';
import { GlobalContext } from '../context/GlobalState';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import { createUserBasedtheme } from './utils/theme';
import NoRoute from '../pages/partners/NoRoute/NoRoute';
import SystemizedServicesHome from '../pages/systemizedServices/SystemizedServicesHome';
import { loginReducer } from '../store/reducers/authReducer';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '../pages/TermsAndConditions/TermsAndConditions';
import AdminLogin from '../pages/admin/AdminLogin/AdminLogin';

const HomePageLazy = React.lazy(() => import('./Home/Homepage'));
const PartnerRouteLazy = React.lazy(() =>
	import('../pages/partners/PartnerRoutes')
);
const AdminRoutesLazy = React.lazy(() => import('./AdminRoutes'));
import ContactUsTbw from './../pages/ContactUsTbw';

function App() {
	// const { auth, userLogin } = useContext(GlobalContext);
	const [result, setResult] = useState(0);
	const setResultFromCalculator = (val) => {
		setResult(val);
	};
	const [recoverEmail, setRecoverEmail] = useState('');

	const auth = useSelector((state) => state.auth);

	const theme = createUserBasedtheme(auth?.themeColor ?? '#294D8F');

	let getMessage = false;
	const dispatch = useDispatch();
	useEffect(() => {
		window.addEventListener('message', async (event) => {
			const allowedDomain = 'https://reapds-centralasia.org';
			if (event.data.token) {
				getMessage = true;

				if (getMessage) {
					if (
						event.data.token &&
						(event.origin === allowedDomain ||
							event.origin === 'http://localhost:3001')
					) {
						const { username, usertype, isLoggedIn, userId, userAgent } =
							event.data.token;
						const token = { username, usertype, isLoggedIn, userId };

						localStorage.setItem('reapToken', JSON.stringify(token));
						// const result = await userLogin(token);
						dispatch(loginReducer(token));
					}
				}
			} else {
				getMessage = false;
			}
		});
	}, []);

	useEffect(() => {
		const link = document.createElement('link');
		link.href =
			'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap';
		link.rel = 'stylesheet';
		document.head.appendChild(link);
		link.href = '../../public/index.css';
		link.rel = 'stylesheet';
		document.head.appendChild(link);
	}, [auth]);

	return (
		<ThemeProvider theme={theme}>
			<MainContainer>
				<CssBaseline />
				<main>
					<Routes>
						<Route path="/" element={<HomePageLazy />} />
						<Route path="/tbw/contact-us" element={<ContactUsTbw />} />
						<Route
							path="/forgot-password"
							element={
								<ForgotPassword
									email={recoverEmail}
									setRecoverEmail={setRecoverEmail}
								/>
							}
						/>
						<Route path="/reset-password" element={<ResetPassword />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route
							path="/terms-and-conditions"
							element={<TermsAndConditions />}
						/>

						<Route
							path="/login"
							element={<Login setRecoverEmail={setRecoverEmail} />}
						/>
						<Route path="/manage" element={<AdminLogin />} />
						<Route path="/not-found" element={<NoRoute />} />

						{/* {auth.isLoggedIn && auth.usertype == 'Admin' ? ( */}
						{auth.isLoggedIn &&
						(auth.userType === 'Admin' || auth.userType === 'Super Admin') ? (
							<Route path="/admin/*" element={<AdminRoutesLazy />} />
						) : (
							<Route
								path="/admin/*"
								element={<Login setRecoverEmail={setRecoverEmail} />}
							/>
						)}

						{auth.isLoggedIn ? (
							<Route
								path="*"
								element={
									<PartnerRouteLazy
										result={result}
										setResults={setResultFromCalculator}
									/>
								}
							/>
						) : (
							<Route
								path="/*"
								element={<Login setRecoverEmail={setRecoverEmail} />}
							/>
						)}
					</Routes>
				</main>
			</MainContainer>
		</ThemeProvider>
	);
}

export default App;
