import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customFetchBaseQuery from '../../components/utils/customBaseQuery';

export const adminApi = createApi({
	reducerPath: 'adminApi',
	baseQuery: customFetchBaseQuery('admin'),
	tagTypes: ['UserTools'],
	endpoints: (builder) => ({
		getAllTools: builder.query({
			query: () => '/get-all-tools', // This is the endpoint for the GET request
		}),
		getUserTools: builder.query({
			// The queryFn receives an object with args
			query: (args) => `/get-user-tools/${args.id}`,
			providesTags: ['UserTools'],
		}),

		getUserById: builder.query({
			// The queryFn receives an object with args
			query: (args) => `/user/${args.id}`,
		}),
		editUser: builder.mutation({
			query: (userData) => ({
				url: '/edit-user-data', // This is the endpoint for the POST request
				method: 'POST',
				body: userData,
			}),
			invalidatesTags: ['UserTools'],
		}),

		assignTools: builder.mutation({
			query: (toolData) => ({
				url: '/assign-tools', // This is the endpoint for the POST request
				method: 'POST',
				body: toolData,
			}),
			invalidatesTags: ['UserTools'],
		}),
		removeTool: builder.mutation({
			query: ({ userId, toolId }) => ({
				url: `/remove-user-tool/${userId}/${toolId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['UserTools'],
		}),
	}),
});

export const {
	useGetAllToolsQuery,
	useAssignToolsMutation,
	useRemoveToolMutation,
	useGetUserToolsQuery,
	useGetUserByIdQuery,
	useEditUserMutation,
} = adminApi;
