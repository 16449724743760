import React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const StyledArrowForwardIcon = styled(ArrowForwardIcon, {
	shouldForwardProp: (prop) => prop !== 'arrowColor' && prop !== 'arrowBgColor',
})(({ arrowColor, arrowBgColor, theme }) => ({
	borderRadius: '50%',
	padding: '3px',
	fontWeight: '700',
	width: '25px',
	height: '25px',
	color: arrowColor || 'inherit', // Apply the arrowColor
	backgroundColor: arrowBgColor || 'inherit', // Apply the arrowBgColor
	[theme.breakpoints.down('md')]: {
		width: '22px',
		height: '22px',
	},
}));

// Style the Button
const StyledButton = styled(Button, {
	shouldForwardProp: (prop) =>
		![
			'bgColor',
			'textColor',
			'arrowColor',
			'arrowBgColor',
			'hoverBgColor',
			'hoverTextColor',
			'hoverArrowColor',
			'hoverArrowBgColor',
			'translateXIcon',
			'translateXText',
			'borderColor',
		].includes(prop),
})(
	({
			theme,
			bgColor,
			textColor,
			arrowColor,
			arrowBgColor,
			hoverBgColor,
			hoverTextColor,
			hoverArrowColor,
			hoverArrowBgColor,
			translateXIcon,
			translateXText,
			borderColor,
		}) =>
		({}) => ({
			backgroundColor: bgColor || 'white',
			color: textColor || theme.palette.common.white,
			borderRadius: '50px',
			padding: '3px 20px 3px 10px',
			minWidth: '130px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			overflow: 'hidden',
			transition: 'all 0.3s ease',
			position: 'relative',
			// height: '40px',
			border: `2px solid ${borderColor || textColor}`,
			textTransform: 'capitalize',
			fontSize: '16px',

			'&:hover': {
				backgroundColor: hoverBgColor || bgColor || 'white', // Use hoverBgColor or default bgColor
				color: hoverTextColor || textColor || 'white', // Use hoverTextColor or default textColor
				borderColor: borderColor || hoverTextColor || textColor || 'white',
			},
			'& .MuiButton-startIcon': {
				transition:
					'transform 0.3s ease, color 0.3s ease, background-color 0.3s ease',
				transform: 'translateX(0)',
				color: arrowColor || 'inherit',
				backgroundColor: arrowBgColor || 'inherit',
				borderRadius: '50%',
				color: arrowColor || 'white',
				backgroundColor: arrowBgColor || 'inherit',
			},

			'&:hover .MuiButton-startIcon': {
				transform: `translateX(${translateXIcon || 0}px)`, // Apply translation on hover
				color: hoverArrowColor || arrowColor || 'white', // Use hoverArrowColor or default arrowColor
				backgroundColor: hoverArrowBgColor || arrowBgColor || 'inherit', // Use hoverArrowBgColor or default arrowBgColor
				border: `1px solid ${borderColor}` || 'inherit',
			},

			'& .button-text': {
				transition: 'transform 0.3s ease',
				transform: 'translateX(0)',
			},

			'&:hover .button-text': {
				transform: `translateX(${translateXText || 0}px)`, // Apply translation on hover
			},
			[theme.breakpoints.down('md')]: {
				height: '34px',
				fontSize: '14px',
				padding: '0px 15px 0px 9px',
				minWidth: '100px',
				'& .button-text': {
					paddingTop: '2px',
				},
			},
		})
);

// Reusable Button Component
const AnimatedButton = ({
	bgColor,
	textColor,
	arrowColor,
	arrowBgColor,
	hoverBgColor,
	hoverTextColor,
	hoverArrowColor,
	hoverArrowBgColor,
	text,
	translateXIcon,
	translateXText,
	borderColor,
}) => {
	return (
		<StyledButton
			variant="contained"
			startIcon={
				<StyledArrowForwardIcon
					arrowColor={arrowColor}
					arrowBgColor={arrowBgColor}
				/>
			}
			bgColor={bgColor}
			textColor={textColor}
			arrowColor={arrowColor}
			arrowBgColor={arrowBgColor}
			hoverBgColor={hoverBgColor}
			hoverTextColor={hoverTextColor}
			hoverArrowColor={hoverArrowColor}
			hoverArrowBgColor={hoverArrowBgColor}
			translateXText={translateXText}
			translateXIcon={translateXIcon}
			borderColor={borderColor}
		>
			<span className="button-text">{text}</span>
		</StyledButton>
	);
};

export default AnimatedButton;
