import { styled } from '@mui/system';
import { Box, Button } from '@mui/material';

export const Navbar = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	height: '70px',
	width: '100%',
	padding: '20px 80px',
	border: '1px solid lightgray',
	boxShadow: '0px 0px 5px lightgray',
	[theme.breakpoints.down('sm')]: {
		padding: '5px 20px',
	},
}));

export const Logo = styled('img')(({ theme }) => ({
	width: '100px',
	height: '50px',
	[theme.breakpoints.down('sm')]: {
		width: '70px',
		height: '35px',
	},
}));

export const StyledButton = styled(Button)(({ theme }) => ({
	border: '1px solid gray',
	borderRadius: '15px',
	color: 'gray',
	backgroundColor: 'white',
	marginRight: '25px',
	[theme.breakpoints.down('sm')]: {
		display: 'none',
	},
}));

export const Links = styled('ul')({
	textDecoration: 'none',
	listStyle: 'none',
});
