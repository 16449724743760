import React, { useState } from 'react';
import {
	Container,
	Typography,
	TextField,
	Button,
	Box,
	Grid,
	Link,
	useMediaQuery,
} from '@mui/material';
import Footer from '../components/Home/Footer';
import axios from 'axios';
import NavbarTbw from '../components/Navbar/NavbarTbw';
import { toast } from 'react-toastify';

const ContactUsTbw = () => {
	const isMobile = useMediaQuery('(max-width:600px)');
	const [emailOrMobile, setEmailOrMobile] = useState('');
	const [emailValid, setEmailValid] = useState(true);

	// A basic email validation regex pattern
	const validateEmail = (email) => {
		const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regexPattern.test(email);
	};

	// A function to validate the phone number for India and Myanmar
	const validatePhoneNumber = (number) => {
		// Regular expression patterns for India and Myanmar phone numbers
		const indiaPattern = /^\+91\d{10}$/;
		const myanmarPattern = /^\+95\d{7,9}$/;

		return indiaPattern.test(number) || myanmarPattern.test(number);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!emailOrMobile) {
			toast.error('Please enter your email address');
			return;
		}
		// let isError = false;

		// if (emailOrMobile.includes('@')) {
		// 	if (validateEmail(email)) {
		// 		isError = true;
		// 		toast.error('Email is not valid.');
		// 		return;
		// 	}
		// } else {
		// 	if (validatePhoneNumber(emailOrMobile)) {
		// 		isError = true;
		// 		toast.error(
		// 			'Mobile number is not valid. Please add coutry code as well with the + sign.'
		// 		);
		// 		return;
		// 	}
		// }

		if (true) {
			try {
				await axios.post(
					`${process.env.REACT_APP_API_KEY}/thabarwa/contact-admin`,
					{
						email: emailOrMobile,
					}
				);
				toast.success(
					'Request sent to admin successfully. We will revert back to you within 24 hours.'
				);
			} catch (error) {
				console.log(error);
				toast.error(
					'Error sending email. Please try after some time or try another method to contact us.'
				);
			}
		} else {
			toast.error(
				'Entered data is wrong. Please enter your registered email or mobile number.'
			);
			// Handle invalid email case
		}
	};
	return (
		<>
			<NavbarTbw />
			<Box sx={{ py: isMobile ? 2 : 5 }}>
				<Container maxWidth="md">
					<Box py={4} textAlign="center">
						<Typography
							sx={{ color: '#878787', mb: 3 }}
							variant="h5"
							gutterBottom
						>
							Thank you for your interest in SUSTENT Group (comprising of STENUM
							Asia Sustainable Development Society and SUSTENT Consulting
							Private Limited).
						</Typography>
						<Typography
							variant="subtitle1"
							sx={{ mb: 5, fontWeight: '600', color: 'black' }}
							gutterBottom
						>
							Please use the below input field to enter your email and let us
							know who you are and we will get back to you shortly.
						</Typography>

						<form noValidate autoComplete="off">
							<Grid container spacing={2} justifyContent="center">
								<Grid item xs={12} sm={12} md={12}>
									<TextField
										required
										id="email"
										label="Your username"
										name="email"
										type="text"
										size="small"
										fullWidth={isMobile}
										sx={{ minWidth: '250px' }}
										value={emailOrMobile}
										onChange={(e) => setEmailOrMobile(e.target.value)}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={12}>
									<Button
										sx={{ backgroundColor: '5cb85c', px: 3 }}
										variant="contained"
										color="primary"
										onClick={handleSubmit}
									>
										Submit
									</Button>
								</Grid>
							</Grid>
						</form>
					</Box>

					<Box py={4} textAlign="center">
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12}>
								<Typography
									variant="subtitle1"
									fontWeight={600}
									color="black"
									gutterBottom
								>
									email us at
								</Typography>
								<Link href="mailto:info@sustent.in" variant="body1">
									info@sustent.in
								</Link>
								<Typography variant="body1" component="span">
									{' OR '}
								</Typography>
								<Link href="mailto:info@stenum-asia.org" variant="body1">
									info@stenum-asia.org
								</Link>
							</Grid>

							<Grid item xs={12} sm={12}>
								<Typography
									fontWeight={600}
									color="black"
									variant="subtitle1"
									gutterBottom
								>
									call us on
								</Typography>
								<Typography variant="body1">+91 124 4116555</Typography>
							</Grid>

							<Grid item xs={12}>
								<Typography variant="subtitle1" gutterBottom>
									If you want to visit us, we are 17 Kms from Delhi Airport. The
									nearest metro station is Moulsari Avenue.
								</Typography>
							</Grid>

							<Grid item xs={12}>
								<Typography
									variant="subtitle1"
									fontWeight={600}
									color="black"
									gutterBottom
								>
									our address is
								</Typography>
								<Typography variant="body1">
									412A Suncity Trade Tower, Sector 21
								</Typography>
								<Typography variant="body1">Gurugram 122016, India.</Typography>
							</Grid>

							<Grid item xs={12}>
								<Typography
									variant="subtitle2"
									fontSize={16}
									fontWeight={600}
									color="black"
								>
									We are open from 9:30am to 5:30pm Mon to Fri excluding public
									holidays.
								</Typography>
							</Grid>
						</Grid>
					</Box>
					<div
						className="embed-responsive embed-responsive-16by9"
						style={{ marginBottom: '30px' }}
					>
						<iframe
							width="100%"
							height="400"
							frameBorder="0"
							scrolling="no"
							marginHeight="0"
							marginWidth="0"
							className="mg1"
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.0594743806264!2d77.06886774938239!3d28.507858296380146!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d195b9a739619%3A0xa921dba1af36138c!2sSUSTENT%20Consulting!5e0!3m2!1sen!2sin!4v1568319330345!5m2!1sen!2sin"
						></iframe>
					</div>
				</Container>
			</Box>
			<Footer />
		</>
	);
};

export default ContactUsTbw;
