import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosInstance from './axiosInterceptors';

const customBaseQuery = (args) => {
	return fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_API_KEY}/${args}`,
	});
};

const customFetchBaseQuery = (args, api, extraOptions) => {
	return customBaseQuery(args, api, {
		...extraOptions,
		fetchFn: axiosInstance, // Use your custom Axios instance
	});
};

export default customFetchBaseQuery;
