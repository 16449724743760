import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBaseQuery from '../../components/utils/customBaseQuery';

export const contentManagerApi = createApi({
	reducerPath: 'contentManagerApi',
	baseQuery: customFetchBaseQuery('content-manager'),
	tagTypes: ['currencies'],
	endpoints: (builder) => ({
		getCurrencies: builder.query({
			query: () => '/get-currencies', // This is the endpoint for the GET request
			providesTags: ['currencies'],
		}),
		updateCurrencies: builder.mutation({
			query: (currencyData) => ({
				url: '/update-currencies', // This is the endpoint for the POST request
				method: 'POST',
				body: currencyData,
			}),
			invalidatesTags: ['currencies'],
		}),
	}),
});

export const { useGetCurrenciesQuery, useUpdateCurrenciesMutation } =
	contentManagerApi;
